import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBold,
  faItalic,
  faTextSlash,
  faListOl,
  faListUl,
  faLink,
  faCode,
  faUpRightFromSquare,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faBold,
  faItalic,
  faTextSlash,
  faListOl,
  faListUl,
  faLink,
  faCode,
  faUpRightFromSquare,
  faMagnifyingGlass,
)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon)
})
