import { default as callbackWC6mXai9NjMeta } from "/home/runner/work/at-frontend/at-frontend/packages/at-auth/lib/pages/callback.vue?macro=true";
import { default as indexz156gPL0E1Meta } from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/pages/index.vue?macro=true";
import { default as indexRZ9anITd4hMeta } from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/pages/post/create/index.vue?macro=true";
import { default as _91id_93Pwxwvdhn7lMeta } from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/pages/post/edit/[id].vue?macro=true";
export default [
  {
    name: "at-auth-callback",
    path: "/_/auth/callback/",
    meta: callbackWC6mXai9NjMeta || {},
    component: () => import("/home/runner/work/at-frontend/at-frontend/packages/at-auth/lib/pages/callback.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/pages/index.vue")
  },
  {
    name: "post-create",
    path: "/post/create",
    component: () => import("/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/pages/post/create/index.vue")
  },
  {
    name: "post-edit-id",
    path: "/post/edit/:id()",
    component: () => import("/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/pages/post/edit/[id].vue")
  }
]