export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig()
  const { $auth } = useNuxtApp()
  const { siteBaseUrl } = config.public['at-auth']

  if ('error' in to.query && to.query.error !== 'login_required') {
    throw createError({
      statusCode: 403,
      statusMessage:
        [to.query.error_description].flat().at(-1) ||
        'An error occurred during authentication.',
    })
  }

  const user = await $auth.signinRedirect({
    redirect_uri: `${siteBaseUrl}/`,
    scope: ['openid', 'profile', 'email', 'timeline:manage'].join(' '),
  })

  // If the "code" parameter is present in the URL,
  // the user has just completed an authorization process.
  // This redirect will remove the "code" parameter from the URL because it's sensitive information.
  if ('code' in to.query) {
    return navigateTo({ name: 'index' })
  }

  // If the user is not authenticated
  if (!user && to.name !== 'at-auth-callback') {
    return navigateTo({ name: 'index' })
  }
})
