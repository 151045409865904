import { UserManager, Log } from 'oidc-client-ts'
import {
  userLoaded,
  currentUser,
  setUserLoaded,
  setCurrentUser,
} from './store/auth'

function createUserManager(siteBaseUrl, authBaseUrl, clientId, scope) {
  if (process.dev) {
    Log.setLevel(Log.INFO)
    Log.setLogger(console)
  }

  const userManager = new UserManager({
    authority: authBaseUrl,
    client_id: clientId,
    redirect_uri: `${siteBaseUrl}/_/auth/callback/`,
    scope: scope ?? 'openid profile email',
    // Not (yet) supported by authlib (https://github.com/lepture/authlib/issues/292)
    monitorSession: false,
    // True to enable nicer handling of anonymous sessions in querySessionStatus,
    // does not actually monitor because monitorSession is false.
    monitorAnonymousSession: true,
    metadata: {
      // Manually set this because AT does not provide a discovery document.
      issuer: authBaseUrl,
      authorization_endpoint: `${authBaseUrl}/account/authorize/`,
      token_endpoint: `${authBaseUrl}/account/token/`,
      revocation_endpoint: `${authBaseUrl}/account/revoke/`,
      userinfo_endpoint: `${authBaseUrl}/account/userinfo/`,
      end_session_endpoint: `${authBaseUrl}/account/logout/`,
    },
  })

  // FIXME: Wrap userManager._client.processSigninResponse and add session_state to the response
  //        This is needed because authlib does not return a session_state and oidc-client-ts
  //        requires it to be present for the session check to work.
  // TODO: Remove this when authlib adds support for session management:
  //       https://github.com/lepture/authlib/issues/292
  const client = userManager._client
  const originalProcessSigninResponse = client.processSigninResponse
  client.processSigninResponse = async function processSigninResponse(url) {
    const response = await originalProcessSigninResponse.call(this, url)
    // session_state is not supposed to be a boolean, it's to fool oidc-client-ts
    // into thinking it's a valid session_state.
    // According to the spec, it should be an opaque string that is unique to the
    // user's session. This is purely a (temporary?) workaround.
    response.session_state = true
    return response
  }
  return userManager
}

/**
 * Mock implementation of the auth client, used on the server.
 */
const mockClient = {
  getReadyState() {
    return false
  },

  getCurrentUser() {
    return null
  },

  signInSilent() {
    if (process.dev) {
      // eslint-disable-next-line no-console
      console.warn('signInSilent() is not supported on the server.')
    }
    return Promise.resolve()
  },

  signinCallback() {
    if (process.dev) {
      // eslint-disable-next-line no-console
      console.warn('signInCallback() is not supported on the server.')
    }
    return Promise.resolve()
  },

  signinRedirect() {
    if (process.dev) {
      // eslint-disable-next-line no-console
      console.warn('signinRedirect() is not supported on the server.')
    }
    return Promise.resolve()
  },
}

export default function authClient(config) {
  const { siteBaseUrl, authBaseUrl, clientId, scope } = config || {}
  if (!siteBaseUrl || !authBaseUrl || !clientId) {
    throw new Error(
      'at-auth: siteBaseUrl, authBaseUrl and clientId are required',
    )
  }

  // OIDC client is not supported on the server.
  if (!process.client) return mockClient

  const userManager = createUserManager(
    siteBaseUrl,
    authBaseUrl,
    clientId,
    scope,
  )

  async function signinSilent() {
    try {
      return await userManager.signinSilent()
    } catch (err) {
      if (err.error === 'login_required') {
        // User is not logged in, this is expected
      } else {
        // eslint-disable-next-line no-console
        console.error('Error during silent sign in:', err)
      }
    }
    return null
  }

  return {
    getReadyState() {
      return userLoaded.value
    },

    getCurrentUser() {
      return currentUser.value
    },

    async signInSilent() {
      // Determine if the user is logged in.
      let user = await userManager.getUser()
      if (!user) {
        // User is not logged in, try to sign in silently.
        user = await signinSilent()
      } else {
        // Make sure the user is still logged in.
        const state = await userManager.querySessionStatus()
        if (state.session_state === null) {
          // User is not logged in anymore!
          // Remove the user from the store.
          await userManager.removeUser()
          user = null
        } else if (state.sub !== user.profile.sub) {
          // User has changed!
          // Remove the user from the store.
          await userManager.removeUser()
          // Try to sign in silently.
          user = await signinSilent()
        }
      }
      setUserLoaded(true)
      setCurrentUser(user)
    },

    async signinRedirect({ redirect_uri, scope }) {
      let user
      // Determine if the user is logged in.
      try {
        user = await userManager.signinCallback()
      } catch {
        user = await userManager.getUser()
      }

      if (!user) {
        // User is not logged in, try to sign in.
        await userManager.signinRedirect({
          redirect_uri,
          scope,
        })
      }

      setUserLoaded(true)
      setCurrentUser(user)

      return user
    },

    async signinCallback() {
      await userManager.signinCallback()
    },

    async signout() {
      await userManager.revokeTokens()
      setUserLoaded(false)
      setCurrentUser(null)
      await userManager.signoutRedirect()
    },
  }
}
