import revive_payload_client_YTGlmQWWT5 from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_H7JebPKzSG from "/home/runner/work/at-frontend/at-frontend/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/.nuxt/sentry-client-config.mjs";
import authPlugin_Bk08l1GInE from "/home/runner/work/at-frontend/at-frontend/packages/at-auth/lib/authPlugin.js";
import apiClient_zyEEH8uBAU from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/plugins/apiClient.ts";
import fontAwesome_OdbcMzCLm1 from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/plugins/fontAwesome.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  sentry_client_H7JebPKzSG,
  sentry_client_config_o34nk2sJbg,
  authPlugin_Bk08l1GInE,
  apiClient_zyEEH8uBAU,
  fontAwesome_OdbcMzCLm1
]