import validate from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/middleware/auth.global.ts";
import token_45api_45client_45global from "/home/runner/work/at-frontend/at-frontend/apps/at-managetimeline/middleware/tokenApiClient.global.ts";
import manifest_45route_45rule from "/home/runner/work/at-frontend/at-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  token_45api_45client_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}