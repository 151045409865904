import type { Author, Post, PaginatedResponse } from '~/types/timeline'

type RequestVerb = 'GET' | 'POST' | 'PATCH' | 'DELETE'
type ErrorResponse = Error & { response: Response }

class ApiClient {
  baseUrl: string
  token: string | null

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    this.token = null
  }

  async request<T>(path: string, method: RequestVerb = 'GET', body?: FormData) {
    const options: RequestInit = {
      method,
    }
    if (method !== 'GET' && body) {
      options.body = body
    }

    const request = new Request(`${this.baseUrl}${path}`, options)

    request.headers.set('Authorization', `Bearer ${this.token}`)

    const response = await fetch(request)

    if (!response.ok) {
      const error = new Error(response.statusText) as ErrorResponse
      error.response = response
      throw error
    }

    // Parse content-type header, e.g. "application/json" or "application/json; charset=utf-8"
    const contentType = response.headers.get('content-type')?.split(';')[0]
    if (contentType === 'application/json') {
      return response.json() as T
    }

    // Unknown content type, throw a ErrorResponse
    const error = new Error('Unknown content type') as ErrorResponse
    error.response = response
    throw error
  }

  setToken(token: string | null = null) {
    this.token = token
  }

  get<T>(path: string) {
    return this.request<T>(path)
  }

  post<T>(path: string, body: FormData) {
    return this.request<T>(path, 'POST', body)
  }

  patch<T>(path: string, body: FormData) {
    return this.request<T>(path, 'PATCH', body)
  }

  delete(path: string) {
    return this.request(path, 'DELETE')
  }

  getPosts(searchParams: URLSearchParams) {
    return this.request<PaginatedResponse<Post>>(
      `/manage/timeline/posts/?${searchParams}`,
    )
  }

  getPostDetail(id: Post['id']) {
    return this.get<Post>(`/manage/timeline/posts/${id}/`)
  }

  getAuthors() {
    // FIXME: 50 is the maximum per-page limit, if there is a need for
    //        more than 50 authors in a dropdown, we should provide
    //        some search functionality to filter the list.
    return this.get<PaginatedResponse<Author>>(
      '/manage/timeline/authors/?limit=50',
    )
  }

  createPost(body: FormData) {
    return this.post<Post>('/manage/timeline/posts/', body)
  }

  updatePost(id: Post['id'], body: FormData) {
    return this.patch<Post>(`/manage/timeline/posts/${id}/`, body)
  }

  deletePost(id: Post['id']) {
    return this.delete(`/manage/timeline/posts/${id}/`)
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  return {
    provide: {
      apiClient: new ApiClient(config.public.apiBaseUrl),
    },
  }
})
