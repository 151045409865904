import { computed, reactive } from 'vue'

const state = reactive({
  userLoaded: false,
  currentUser: null,
})

export const userLoaded = computed(() => state.userLoaded)
export const currentUser = computed(() => state.currentUser)

export const setUserLoaded = (userLoaded) => (state.userLoaded = userLoaded)
export const setCurrentUser = (currentUser) => (state.currentUser = currentUser)
