<script lang="ts">
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    is404() {
      return this.error.statusCode === 404
    },
  },
}
</script>

<template>
  <NuxtLayout>
    <div class="space-y-2 text-gray-800">
      <h1 class="font-semibold">
        {{ is404 ? 'Page not found' : 'Something went wrong' }}
      </h1>
      <p v-if="!is404">We're sorry. Our team is informed.</p>
      <p>{{ error }}</p>
    </div>
  </NuxtLayout>
</template>
